export const data = {
  "$^AboutUs^$": "About Us",
  "$^CATEGORY^$": "Category",
  "$^ContactUs^$": "Contact Us",
  "$^Country^$": "Country",
  "$^FindAStoreBuyNow^$": "Find A Store",
  "$^Menu^$": "Menu",
  "$^Newsroom^$": "Newsroom",
  "$^Product-Pro^$": "Pro Exclusive",
  "$^ProductCare^$": "Product Care",
  "$^ProductService^$": "Product Service",
  "$^ProfessionalUse^$": "Arc'teryx PRO",
  "$^Professional^$": "$^Professional^$",
  "$^PromoHolidayShippingAlert^$": "HOLIDAY SHIPPING DEADLINE DEC. 17",
  "$^SignUpBodyCopy2^$": "Subscribe to receive new product releases, exclusive discount codes, and invites to events.",
  "$^SignUpCta2^$": "Sign up for emails",
  "$^SignUpTitle^$": "Be the first to know",
  "$^System-A^$": "$^System-A^$",
  "$^Veilance^$": "$^Veilance^$",
  "$^arc-naming-scheme^$": "Arc'teryx Naming Scheme",
  "$^climbing-lookbook^$": "Climbing Lookbook",
  "$^community-events^$": "Community Events",
  "$^explore^$": "Explore",
  "$^gift-guide^$": "Gift Guide",
  "$^filterBar-collection^$": "Collection",
  "$^footer-beFirstToKnow^$": "Be the First to Know",
  "$^footer-findArcStore^$": "Find An Arc'teryx Store",
  "$^footer-followUs-header^$": "Follow Us",
  "$^footer-locateBrandStoreOrPartner^$": "Locate a brand store, partner store or retail partner in your area.",
  "$^footer-nav-campaign-and-events^$": "Campaign & Event",
  "$^footer-nav-careers^$": "Careers",
  "$^footer-nav-toTop^$": "top",
  "$^footer-newMoreInfoHelp^$": "Need more information? Have a repair concern? No problem. We're here to help.",
  "$^footer-pro-program^$": "Pro Program",
  "$^footer-recall-info^$": "Recall Info",
  "$^footer-sitemap^$": "Sitemap",
  "$^freeShippingAndReturnsMsgJP^$": "Free shipping on all orders – free returns within 30 days",
  "$^nav-CommuterBags^$": "Commuter Bags",
  "$^nav-IntendedUse^$": "Intended Use",
  "$^nav-ShopMore^$": "Shop More",
  "$^nav-arcAcademy^$": "Arc'teryx Academy",
  "$^nav-arcFavourites^$": "Arc' Favourites",
  "$^nav-avalancheAirbags^$": "Avalanche Airbags",
  "$^nav-birdBlog^$": "Bird Blog",
  "$^nav-boots^$": "Boots",
  "$^nav-chalkBags^$": "Chalk Bags",
  "$^nav-daypacks^$": "Daypacks",
  "$^nav-duffleBagsAndTotes^$": "Duffle & Totes",
  "$^nav-fallLookbook^$": "Fall Lookbook",
  "$^nav-find-answers^$": "Find Answers",
  "$^nav-giftCards^$": "Gift Cards",
  "$^nav-gifts-for-him^$": "Gifts For Him",
  "$^nav-gloves^$": "Gloves",
  "$^nav-guidePicks^$": "Guide Picks",
  "$^nav-harnesses^$": "Harnesses",
  "$^nav-hatsAndCaps^$": "Hats & Caps",
  "$^nav-hikingAndTrekking^$": "Hiking & Trekking",
  "$^nav-hydration-vests^$": "Hydration Vests",
  "$^nav-layering-guide^$": "Layering Guide",
  "$^nav-midLayerAndFleece^$": "Mid Layer & Fleece",
  "$^nav-midLayer^$": "Mid Layer",
  "$^nav-more^$": "More...",
  "$^nav-multiDay^$": "Multi-day",
  "$^nav-ourStory^$": "Our Story",
  "$^nav-problemSolvers^$": "The Problem Solvers",
  "$^nav-ropeBags^$": "Rope Bags",
  "$^nav-shellFinder^$": "Jacket Finder",
  "$^nav-gift-guide^$": "Gift Guide",
  "$^nav-footwearFinder^$": "Footwear Finder",
  "$^nav-shoes^$": "Shoes",
  "$^nav-skiAndSnowboard^$": "Skiing & Snowboarding",
  "$^nav-springLookbook^$": "Spring Lookbook",
  "$^nav-sustainability^$": "Sustainability",
  "$^nav-toquesAndBeanies^$": "Toques & Beanies",
  "$^nav-trail-running^$": "Trail Running",
  "$^nav-travelAndCommute^$": "Travel & Commute",
  "$^nav-travelBags^$": "Travel Bags",
  "$^nav-travelCollection^$": "Travel Collection",
  "$^nav-veilance^$": "Veilance",
  "$^nav-whoweare^$": "Who We Are",
  "$^nav-winterLookbook^$": "Winter Lookbook",
  "$^telephoneAssistanceES^$": "$^telephoneAssistanceES^$",
  "$^telephoneAssistanceIT^$": "$^telephoneAssistanceIT^$",
  "$^whatsNew^$": "What's New",
  "10 Years": "10 Years",
  "About Us": "About Us",
  "Accessibility": "Accessibility",
  "Accessories": "Accessories",
  "Alpine & Rock Climbing": "Alpine & Rock Climbing",
  "Android App": "Android App",
  "Arc'teryx Limited Warranty": "Arc'teryx Limited Warranty",
  "Arc'teryx Name": "Arc'teryx",
  "Arc'teryx Outlet FAQ": "Arc'teryx Outlet FAQ",
  "Arc'type": "Arc'type",
  "Athletes & Ambassadors": "Athletes & Ambassadors",
  "Base Layer": "Base Layer",
  "Bottoms": "Bottoms",
  "Brand": "Brand",
  "CA Supply Chains Act": "CA Supply Chains Act",
  "Checkout": "Checkout",
  "Climate": "Climate",
  "Climbing Gear": "Climbing Gear",
  "Clothing": "Clothing",
  "Collection": "Collection",
  "Cookie Preference Centre": "Cookie Preference Centre",
  "Cookie Policy": "Cookie Policy",
  "Customer Support Centre": "Customer Support Centre",
  "Do Not Sell My Personal Information": "Do Not Sell My Personal Information",
  "Editorial": "Editorial",
  "Email List": "Email List",
  "Everyday": "Everyday",
  "Exclusives": "Back in Stock",
  "FREE RETURNS": "FREE RETURNS",
  "FREE SHIPPING | FREE RETURNS": "FREE SHIPPING / FREE RETURNS",
  "Fall/Winter 2020": "Fall/Winter 2020",
  "Fall/Winter 2021": "Fall/Winter 2021",
  "Fast and Light": "Fast and Light",
  "Favourites": "Favourites",
  "Fleece": "Fleece",
  "Climbing-Gear": "Climbing Gear",
  "Footwear": "Footwear",
  "Free 2 Day Shipping": "Free Express Shipping",
  "Free Returns": "FREE RETURNS",
  "Freedom of Movement": "Freedom of Movement",
  "Hot/Humid": "Hot/Humid",
  "Insulated Jackets": "Insulated Jackets",
  "Jackets": "Jackets",
  "Join Our Mailing List": "Join Our Mailing List",
  "Just Added": "Just Added",
  "Learn More": "Learn More",
  "Leather Casing Goods": "Leather Casing Goods",
  "Men's": "Men's",
  "Mens": "Men",
  "My Account": "My Account",
  "My Profile": "My Profile",
  "New Arrivals": "New Arrivals",
  "Beta Family": "Beta Family",
  "Gamma Collection": "Gamma Collection",
  "Sun-Protection Collection": "Sun-Protection Collection",
  "Athlete Picks": "Athlete Picks",
  "Online Exclusives": "Online Exclusives",
  "Order Tracking": "Order Tracking",
  "Outlet Name": "Outlet",
  "Packs": "Packs",
  "Pants": "Pants",
  "Past, Present, and Future": "Past, Present, and Future",
  "Performance Denim": "Performance Denim",
  "Privacy Policy": "Privacy Policy",
  "Problem Solvers": "Problem Solvers",
  "Procline Boots Safety Recall": "Procline Boots Safety Recall",
  "Procline Voluntary Recall": "Arc'teryx has issued a voluntary recall on all 2016 Procline Boots",
  "Product Care": "Product Care",
  "Product Service": "Product Service",
  "Professional Use": "Arc'teryx PRO",
  "Rain": "Rain",
  "Re-System": "Re-System",
  "Re-System Name": "Re-System",
  "Returns & Refunds": "Returns & Refunds",
  "Search": "search",
  "Severe Cold": "Severe Cold",
  "Shell Jackets": "Shell Jackets",
  "Just Landed": "Just Landed",
  "Deeper Discounts": "Deeper Discounts",
  "Shipping & Delivery": "Shipping & Delivery",
  "Shirts & Tops": "Shirts & Tops",
  "Shirts & Tanks": "Shirts & Tanks",
  "Shop": "Shop",
  "Shop Arc'teryx": "Shop Arc'teryx",
  "Shop Outlet": "Shop Outlet",
  "Shop Used Gear": "Shop Used Gear",
  "Shorts": "Shorts",
  "Sign Up for Emails": "Sign Up for Emails",
  "Sign-In": "Sign-In",
  "Sign-Out": "Sign-Out",
  "Sizing & Fit Guide": "Sizing & Fit Guide",
  "Ski & Snowboard Collection": "Outerwear Bestsellers",
  "Dresses & Skirts": "Dresses & Skirts",
  "Socks": "Socks",
  "Specified Commercial Transaction Act": "Specified Commercial Transaction Act",
  "Spring/Summer 2020": "Spring/Summer 2020",
  "Spring/Summer 2021": "Spring/Summer 2021",
  "Spring/Summer 2022": "Spring/Summer 2022",
  "Stay updated with brand and product news.": "Stay updated with brand and product news.",
  "Student Discount": "Student Discount",
  "System_A Name": "System_A",
  "Terms & Conditions": "Terms & Conditions",
  "Terms of Use": "Terms of Use",
  "Tops": "Tops",
  "Used Gear Name": "Used Gear",
  "REGEAR": "REGEAR",
  "Veilance Name": "Veilance",
  "Veilance Womens": "Veilance Womens",
  "Veilance X Chito": "Veilance X Chito",
  "Veilance is a brand of Arc'teryx Equipment arcteryx.com": "Veilance is a brand of Arc'teryx Equipment <a href=\"https://arcteryx.com\">arcteryx.com</a>",
  "General FAQ": "General FAQ",
  "Wind": "Wind",
  "Women's": "Women's",
  "Womens": "Women",
  "free Shipping And Returns Msg JP": "Free shipping on all orders – free returns within 30 days",
  "iOS App": "iOS App",
  "WCAG Statement": "Arc’teryx is committed to achieving Level AA conformance for this website in conformance with the Web Content Accessibility Guidelines (WCAG) 2.0 and achieving compliance with other accessibility standards. Please contact Customer Service at +1 866 458 2473 (toll free), if you have any issues accessing information on this website.",
  "Obsessive Design": "Obsessive Design",
  "Product Advice": "Arc'teryx Advice",
  "Black Friday Sale": "Black Friday Sale",
  "Sale": "Sale",
  "Women's Black Friday Sale": "Women's Black Friday Sale",
  "Men's Black Friday Sale": "Men's Black Friday Sale",
  "Colour Collection": "Colour Collection",
  "What's Your Warmest": "What's Your Warmest",
  "For Him": "For Him",
  "Gifts for Him": "Gifts for Him",
  "Best Sellers": "Bestsellers",
  "Gifts Under $50": "Gifts Under $50",
  "Gifts Under £50": "Gifts Under £50",
  "Gifts Under KR 500": "Gifts Under KR 500",
  "Gifts Under NOK 500": "Gifts Under NOK 500",
  "Gifts Under PLN 200": "Gifts Under PLN 200",
  "Gifts Under CHF 50": "Gifts Under CHF 50",
  "Gifts Under €50": "Gifts Under €50",
  "Gifts Under $100": "Gifts Under $100",
  "Gifts Under £100": "Gifts Under £100",
  "Gifts Under KR 1000": "Gifts Under KR 1000",
  "Under NOK 1000": "Under NOK 1000",
  "Under PLN 500": "Under PLN 500",
  "Under CHF 100": "Under CHF 100",
  "Under €100": "Under €100",
  "Under $200": "Under $200",
  "Under £200": "Under £200",
  "Under KR 2000": "Under KR 2000",
  "Under NOK 2000": "Under NOK 2000",
  "Under PLN 1000": "Under PLN 1000",
  "Under CHF 200": "Under CHF 200",
  "Under €200": "Under €200",
  "Run Gifts for Him": "Run Gifts for Him",
  "Climb Gifts for Him": "Climb Gifts for Him",
  "Ski + Snow for Him": "Ski + Snow for Him",
  "Hike Gifts for Him": "Hike Gifts for Him",
  "Veilance Gifts for Him": "Veilance Gifts for Him",
  "For Her": "For Her",
  "Gifts for Her": "Gifts for Her",
  "Run Gifts for Her": "Run Gifts for Her",
  "Climb Gifts for Her": "Climb Gifts for Her",
  "Ski + Snow for Her": "Ski + Snow for Her",
  "Hike Gifts for Her": "Hike Gifts for Her",
  "Veilance Gifts for Her": "Veilance Gifts for Her",
  "Year of the Dragon Capsule": "Year of the Dragon Capsule",
  "ePE & Fabric Sustainability": "ePE & Fabric Sustainability",
  "Men's Footwear": "Men's Footwear",
  "Men's Hike": "Men's Hike",
  "Men's Climb": "Men's Climb",
  "Men's Run": "Men's Run",
  "Women's Footwear": "Women's Footwear",
  "Women's Hike": "Women's Hike",
  "Women's Climb": "Women's Climb",
  "Women's Run": "Women's Run",
  "Footwear Innovation": "Footwear Innovation",
  "Ski-and-Snowboard": "Ski & Snowboard",
  "Touring": "Touring",
  "Freeride": "Freeride",
  "Resort": "Resort",
  "Trail": "Trail",
  "Mountain Run": "Mountain Run",
  "Hike": "Hike",
  "Alpine": "Alpine",
  "Rock": "Rock",
  "Boulder": "Boulder",
  "Climbing": "Climb",
  "EverydayEvolution": "Everyday",
  "Micon LiTRIC Avalanche Airbag": "Micon LiTRIC Avalanche Airbag",
  "Stocking Stuffers": "Stocking Stuffers",
  "Small Gifts": "Small Gifts",
  "Warm Layers": "Warm Layers",
  "US-BlackFriday": "Black Friday Bestsellers",
};