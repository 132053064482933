export const data = {
  "$^AboutUs^$": "Sobre nosotros",
  "$^CATEGORY^$": "Categoría",
  "$^ContactUs^$": "Contacte con nosotros",
  "$^Country^$": "País",
  "$^FindAStoreBuyNow^$": "Encontrar una tienda",
  "$^Menu^$": "Menú",
  "$^Newsroom^$": "Newsroom",
  "$^Product-Pro^$": "Exclusivo Pro",
  "$^ProductCare^$": "Cuidados del producto",
  "$^ProductService^$": "Servicio de producto",
  "$^ProfessionalUse^$": "Arc'teryx PRO",
  "$^Professional^$": "$^Professional^$",
  "$^PromoHolidayShippingAlert^$": "PLAZO LÍMITE DE ENVÍO DURANTE LAS FIESTAS: 17",
  "$^SignUpBodyCopy2^$":
    "Suscríbete para recibir novedades sobre productos, códigos de descuento exclusivos e invitaciones a eventos.",
  "$^SignUpCta2^$": "SUSCRIBIRSE PARA RECIBIR EMAILS",
  "$^SignUpTitle^$": "ENTÉRATE ANTES QUE NADIE",
  "$^System-A^$": "$^System-A^$",
  "$^Veilance^$": "$^Veilance^$",
  "$^arc-naming-scheme^$": "Nomenclatura Arc'teryx",
  "$^climbing-lookbook^$": "Lookbook escalada",
  "$^community-events^$": "Eventos de la Comunidad",
  "$^explore^$": "Explorar",
  "$^gift-guide^$": "Guía De Regalos",
  "$^filterBar-collection^$": "Colección",
  "$^footer-beFirstToKnow^$": "Sea el primero en enterarse",
  "$^footer-findArcStore^$": "Encontrar una tienda Arc'teryx",
  "$^footer-followUs-header^$": "SÍGUENOS",
  "$^footer-locateBrandStoreOrPartner^$":
    "Encuentra una tienda oficial o una tienda distribuidora o asociada en tu zona.",
  "$^footer-nav-campaign-and-events^$": "Campaña y evento",
  "$^footer-nav-careers^$": "Empleo",
  "$^footer-nav-toTop^$": "Arriba",
  "$^footer-newMoreInfoHelp^$":
    "¿Necesitas más información? ¿Necesitas una reparación? No hay problema, estamos aquí para ayudarte.",
  "$^footer-pro-program^$": "Programa Pro",
  "$^footer-recall-info^$": "Información sobre la retirada",
  "$^footer-sitemap^$": "Sitemap",
  "$^freeShippingAndReturnsMsgJP^$": "ENVÍO EN DOS DÍAS GRATUITO / DEVOLUCIONES GRATUITAS",
  "$^nav-CommuterBags^$": "Mochila urbana",
  "$^nav-IntendedUse^$": "Uso previsto",
  "$^nav-ShopMore^$": "Comprar más",
  "$^nav-arcAcademy^$": "Academia Arc'teryx",
  "$^nav-arcFavourites^$": "Favoritos de Arc’teryx",
  "$^nav-avalancheAirbags^$": "Airbag para avalanchas",
  "$^nav-birdBlog^$": "Blog Bird",
  "$^nav-boots^$": "Botas",
  "$^nav-chalkBags^$": "Bolsas de magnesio",
  "$^nav-daypacks^$": "Mochilas",
  "$^nav-duffleBagsAndTotes^$": "Bolsas de viaje & totes",
  "$^nav-fallLookbook^$": "Lookbook de otoño",
  "$^nav-find-answers^$": "Encontrar respuestas",
  "$^nav-giftCards^$": "Tarjetas regalo",
  "$^nav-gifts-for-him^$": "Regalos Para Él",
  "$^nav-gloves^$": "Guantes",
  "$^nav-guidePicks^$": "Selecciones de los guías",
  "$^nav-harnesses^$": "Arneses",
  "$^nav-hatsAndCaps^$": "Sombreros & gorras",
  "$^nav-hikingAndTrekking^$": "Senderismo & excursionismo",
  "$^nav-hydration-vests^$": "Chalecos de hidratación",
  "$^nav-layering-guide^$": "Guía de combinación de capas",
  "$^nav-midLayerAndFleece^$": "Capa intermedia y tejido polar",
  "$^nav-midLayer^$": "Capa intermedia",
  "$^nav-more^$": "Más",
  "$^nav-multiDay^$": "Para varios días",
  "$^nav-ourStory^$": "Nuestra historia",
  "$^nav-problemSolvers^$": "Los que resuelven problemas",
  "$^nav-ropeBags^$": "Bolsas para cuerdas",
  "$^nav-shellFinder^$": "Buscador de chaquetas",
  "$^nav-gift-guide^$": "Guía De Regalos",
  "$^nav-footwearFinder^$": "Buscador de calzado",
  "$^nav-shoes^$": "Calzado",
  "$^nav-skiAndSnowboard^$": "Esquí & snowboard",
  "$^nav-springLookbook^$": "Lookbook de primavera",
  "$^nav-sustainability^$": "Sostenibilidad",
  "$^nav-toquesAndBeanies^$": "Gorros",
  "$^nav-trail-running^$": "Carreras de montaña",
  "$^nav-travelAndCommute^$": "Viajes & trayectos al trabajo",
  "$^nav-travelBags^$": "Bolsas de viaje",
  "$^nav-travelCollection^$": "Viajar",
  "$^nav-veilance^$": "Veilance",
  "$^nav-whoweare^$": "Quiénes somos",
  "$^nav-winterLookbook^$": "Catálogo de invierno",
  "$^telephoneAssistanceES^$": "$^telephoneAssistanceES^$",
  "$^telephoneAssistanceIT^$": "$^telephoneAssistanceIT^$",
  "$^whatsNew^$": "Novedades",
  "10 Years": "10 años",
  "About Us": "Acerca de nosotros",
  Accessibility: "Accessibility",
  Accessories: "Accesorios",
  "Alpine & Rock Climbing": "Alpinismo & escalada",
  "Android App": "Android App",
  "Arc'teryx Limited Warranty": "Garantía limitada Arc'teryx",
  "Arc'teryx Name": "Arc'teryx",
  "Arc'teryx Outlet FAQ": "Preguntas frecuentes Arc'teryx Outlet",
  "Arc'type": "Arc'type",
  "Athletes & Ambassadors": "Deportistas y Embajadores",
  "Base Layer": "Capa base",
  Bottoms: "Pantalones",
  Brand: "Marca",
  "CA Supply Chains Act": "CA Supply Chains Act",
  Checkout: "Pagar",
  Climate: "Clima",
  "Climbing Gear": "Equipo de alpinismo",
  Clothing: "Ropa",
  Collection: "COLECCIÓN",
  "Cookie Preference Centre": "Centro de preferencias de cookies",
  "Cookie Policy": "Política de cookies",
  "Customer Support Centre": "CENTRO DE ATENCIÓN AL CLIENTE",
  "Do Not Sell My Personal Information": "No vender mi información personal",
  Editorial: "Editorial",
  "Email List": "Lista de correo",
  Everyday: "Everyday",
  Exclusives: "De nuevo en stock",
  "FREE RETURNS": "DEVOLUCIONES GRATUITAS",
  "FREE SHIPPING | FREE RETURNS": "ENVÍO GRATUITO / DEVOLUCIONES GRATUITAS",
  "Fall/Winter 2020": "Fall/Winter 2020",
  "Fall/Winter 2021": "Fall/Winter 2021",
  "Fast and Light": "Fast and Light",
  Favourites: "Favoritos",
  Fleece: "Tejido polar",
  "Climbing-Gear": "Equipo de alpinismo",
  Footwear: "Calzado",
  "Free 2 Day Shipping": "ENVÍO EXPRÉS GRATUITO",
  "Free Returns": "DEVOLUCIONES GRATUITAS",
  "Freedom of Movement": "Freedom of Movement",
  "Hot/Humid": "Calor/Humedad",
  "Insulated Jackets": "Chaquetas con aislamiento",
  Jackets: "Chaquetas",
  "Join Our Mailing List": "Suscríbase a nuestra lista de correo",
  "Just Added": "Novedades",
  "Learn More": "Más Información",
  "Leather Casing Goods": "Carteras en piel",
  Mens: "Hombre",
  "My Account": "Mi Cuenta",
  "My Profile": "Mi perfil",
  "New Arrivals": "Novedades",
  "Beta Family": "Familia Beta",
  "Gamma Collection": "Colección Gamma",
  "Sun-Protection Collection": "Colección protección UV",
  "Athlete Picks": "Favoritos",
  "Online Exclusives": "Online Exclusives",
  "Order Tracking": "Seguimiento de pedidos",
  "Outlet Name": "Outlet",
  Packs: "Mochilas",
  Pants: "Pantalones",
  "Past, Present, and Future": "Pasado, presente y futuro",
  "Performance Denim": "Performance Denim",
  "Privacy Policy": "Política de privacidad",
  "Problem Solvers": "Solucionadores de problemas",
  "Procline Boots Safety Recall": "Retirada voluntaria de Procline",
  "Procline Voluntary Recall":
    "Arc'teryx Equipment está retirando voluntariamente los siguientes modelos de botas Procline",
  "Product Care": "Cuidados del producto",
  "Product Service": "Servicio de productos",
  "Professional Use": "Arc'teryx PRO",
  Rain: "Lluvia",
  "Re-System": "Re-System",
  "Re-System Name": "Re-System",
  "Returns & Refunds": "Devoluciones y reembolsos",
  Search: "buscar",
  "Severe Cold": "Frío extremo",
  "Shell Jackets": "Chaquetas shell",
  "Just Landed": "Recién llegados",
  "Deeper Discounts": "Aún más descuento",
  "Shipping & Delivery": "Envío y transporte",
  "Shirts & Tops": "Camisetas",
  "Shirts & Tanks": "Camisetas y Tanks",
  Shop: "Comprar",
  "Shop Arc'teryx": "Comprar productos Arc'teryx",
  "Shop Outlet": "Comprar en el Outlet",
  "Shop Used Gear": "Comprar artículos del programa Used Gear",
  Shorts: "Pantalones cortos",
  "Sign Out": "Cerrar sesión",
  "Sign Up for Emails": "Suscribirse a nuestra lista de correo",
  "Sign-In": "Inscríbase",
  "Sign-Out": "Sign-Out",
  "Sizing & Fit Guide": "Tabla de tallas",
  "Ski & Snowboard Collection": "Prendas Más Vendidas",
  "Dresses & Skirts": "Vestidos & Faldas",
  Socks: "Calcetines",
  "Specified Commercial Transaction Act": "Specified Commercial Transaction Act",
  "Spring/Summer 2020": "Spring/Summer 2020",
  "Spring/Summer 2021": "Primavera/Verano 2021",
  "Spring/Summer 2022": "Primavera/Verano 2022",
  "Stay updated with brand and product news.": "Esté al corriente con noticias sobre la marca y los productos.",
  "Student Discount": "Descuento para estudiantes",
  "System_A Name": "System_A",
  "Terms & Conditions": "Términos y condiciones",
  "Terms of Use": "Condiciones de uso",
  Tops: "Parte superior",
  "Used Gear Name": "Used Gear",
  REGEAR: "REGEAR",
  "Veilance Name": "Veilance",
  "Veilance Womens": "Veilance Womens",
  "Veilance X Chito": "Veilance X Chito",
  "Veilance is a brand of Arc'teryx Equipment arcteryx.com":
    'Veilance es una marca de Arc\'teryx Equipment <a href="https://arcteryx.com">arcteryx.com</a>',
  "General FAQ": "Preguntas frecuentes",
  Wind: "Viento",
  Womens: "Mujer",
  "free Shipping And Returns Msg JP": "ENVÍO EN DOS DÍAS GRATUITO / DEVOLUCIONES GRATUITAS",
  "iOS App": "iOS App",
  "WCAG Statement":
    "Arc’teryx is committed to achieving Level AA conformance for this website in conformance with the Web Content Accessibility Guidelines (WCAG) 2.0 and achieving compliance with other accessibility standards. Please contact Customer Service at +1 866 458 2473 (toll free), if you have any issues accessing information on this website.",
  "Obsessive Design": "Obsessive Design",
  "Product Advice": "Arc'teryx Advice",
  "Black Friday Sale": "Rebajas Del Black Friday",
  "Sale": "Rebajas",
  "Women's Black Friday Sale": "Rebajas Black Friday mujer",
  "Men's Black Friday Sale": "Rebajas Black Friday hombre",
  "Colour Collection": "Colour Collection",
  "What's Your Warmest": "Lo que más te abriga",
  "For Him": "Para él",
  "Gifts for Him": "Regalos para él",
  "Best Sellers": "Los más vendidos",
  "Gifts Under $50": "Regalos por menos de $ 50",
  "Gifts Under £50": "Regalos por menos de £ 50",
  "Gifts Under KR 500": "Regalos por menos de KR 500",
  "Gifts Under NOK 500": "Regalos por menos de NOK 500",
  "Gifts Under PLN 200": "Regalos por menos de PLN 200",
  "Gifts Under CHF 50": "Regalos por menos de CHF 50",
  "Gifts Under €50": "Regalos por menos de € 50",
  "Gifts Under $100": "Regalos por menos de $ 100",
  "Gifts Under £100": "Regalos por menos de £ 100",
  "Gifts Under KR 1000": "Regalos por menos de KR 1000",
  "Under NOK 1000": "Regalos por menos de NOK 1000",
  "Under PLN 500": "Regalos por menos de PLN 500",
  "Under CHF 100": "Regalos por menos de CHF 100",
  "Under €100": "Regalos por menos de € 100",
  "Under $200": "Regalos por menos de $ 200",
  "Under £200": "Regalos por menos de £ 200",
  "Under KR 2000": "Regalos por menos de KR 2000",
  "Under NOK 2000": "Regalos por menos de NOK 2000",
  "Under PLN 1000": "Regalos por menos de PLN 1000",
  "Under CHF 200": "Regalos por menos de CHF 200",
  "Under €200": "Regalos por menos de € 200",
  "Run Gifts for Him": "Regalos para runners",
  "Climb Gifts for Him": "Regalos para amantes de la escalada",
  "Ski + Snow for Him": "Regalos para amantes del esquí y el snowboard",
  "Hike Gifts for Him": "Regalos para amantes del senderismo",
  "Veilance Gifts for Him": "Regalos Veilance",
  "For Her": "Para ella",
  "Gifts for Her": "Regalos para ella",
  "Run Gifts for Her": "Regalos para runners",
  "Climb Gifts for Her": "Regalos para amantes de la escalada",
  "Ski + Snow for Her": "Regalos para amantes del esquí y el snowboard",
  "Hike Gifts for Her": "Regalos para amantes del senderismo",
  "Veilance Gifts for Her": "Regalos Veilance",
  "Year of the Dragon Capsule": "Colección cápsula Año del Dragón",
  "ePE & Fabric Sustainability": "ePE y Sostenibilidad de los Tejidos",
  "Men's Footwear": "Calzado para hombre",
  "Men's Hike": "Senderismo hombre",
  "Men's Climb": "Escalada hombre",
  "Men's Run": "Running hombre",
  "Women's Footwear": "Calzado para mujer",
  "Women's Hike": "Senderismo mujer",
  "Women's Climb": "Escalada mujer",
  "Women's Run": "Running mujer",
  "Footwear Innovation": "Calzado innovador",
  "Ski-and-Snowboard": "Ski & Snowboard",
  "Touring": "Travesía",
  "Freeride": "Freeride",
  "Resort": "Estación",
  "Trail": "Trail",
  "Mountain Run": "Alpinismo ligero",
  "Hike": "Senderismo",
  "Alpine": "Alpinismo",
  "Rock": "Escalada en roca",
  "Boulder": "Escalada en bloque",
  "Climbing": "Escalada",
  "EverydayEvolution": "Uso diario",
  "Micon LiTRIC Avalanche Airbag": "Airbag de avalancha MiCON LiTRIC",
  "Stocking Stuffers": "Regalitos",
  "Small Gifts": "Regalitos",
  "Warm Layers": "Capas abrigadas",
  "US-BlackFriday": "Black Friday Bestsellers",
};
